<template>
  <div id="firstDiv">
    <van-row id="topRow1">
      <van-col span="22">
        <van-nav-bar title="收支" left-text="" left-arrow>
          <template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </van-col>
      <!--    <van-col span="2" id="topCol3"><van-icon name="wap-nav" /></van-col>-->

    </van-row>
    <van-dropdown-menu>
      <van-dropdown-item v-model="value1" :options="option1" />
      <van-dropdown-item v-model="value2" :options="option2" />
      <van-dropdown-item v-model="value3" :options="option3" />
    </van-dropdown-menu>
  </div>

</template>






<script>
import {ref} from "vue";
import {Toast} from "vant";

export default {
  name: "topView",
  setup() {
    const value1 = ref(0);
    const value2 = ref('a');
    const value3 = ref('a');
    const option1 = [
      { text: '2022.03', value: 0 },
      { text: '2022.04', value: 1 },
      { text: '2022.05', value: 2 },
    ];
    const option2 = [
      { text: '全部账户', value: 'a' },
    ];
    const option3 = [
      { text: '筛选', value: 'a' },
    ];
    const onClickLeft = () => history.back();
    const onClickRight = () => Toast('按钮');
    return {
      value1,
      value2,
      value3,
      option1,
      option2,
      option3,
      onClickLeft,
      onClickRight,
    };
  }
}
</script>

<style scoped>
#firstDiv{
  margin: 10px 0 25px 25px
}
</style>
