import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FirstView from "@/views/FirstView";
import AccountView from "@/views/AccountView";
import LoginView from "@/views/LoginView";
import topView from "@/views/topView";
const routes = [
  {
    path: '/1',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }, {
    path: '/top',
    name: 'top',
    component: topView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/',
    name: 'first',
    component: FirstView,
    meta:{
      requiresAuth:true,
      name:'首页'
    },
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    meta:{
      requiresAuth:true,
      name:'账务查询'
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
