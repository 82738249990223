<template>
  <div>


    <img v-on:click="goAccount" src="../assets/first.jpg">
  </div>
</template>

<script>
export default {
  name: 'FirstView',
  data(){

  },
  methods:{
    goAccount(){
      this.$router.push("/account");
    }
  }
}
</script>

<style scoped>

</style>
