import { createStore } from 'vuex'
import state  from '@/store/defaultState'
import mutations  from '@/store/mutations'
export default createStore({
  state: state,
  getters: {
  },
  mutations: mutations,
  actions: {
  },
  modules: {
  }
})
