// import axios from "axios";
import axios from "./http";
import store from "@/store"

export function fetchPermission(){
    return axios.get("/api/permission?user="+store.state.UserToken);
}

export function login(userName,password){
    return axios.get("/api/account/login?userName="+userName+"&password="+password)
}

export function init(queryVo){
    return axios.post("/api/bank/init",queryVo)
}
export function month(){
    return axios.get("/api/bank/month");
}
export function getTotal(date){
    return axios.get("/api/account/login?date="+date)
}
