<template>
  <van-row id="topRow1">
    <van-col span="22">
      <van-nav-bar title="收支" left-text="" left-arrow>
        <template #right>
          <van-icon name="search" size="18" />
        </template>
      </van-nav-bar>
    </van-col>

  </van-row>

  <van-dropdown-menu>
    <van-dropdown-item  v-model="value1" :options="months" />
    <van-dropdown-item v-model="value2" :options="option2" />
    <van-dropdown-item v-model="value3" :options="option3" />
  </van-dropdown-menu>

  <div id="firstDiv">
    <div id="top2Div">
      <van-row id="top2Row1">
        <van-col span="8" id="top2Col1"><span id="top2Span" >{{value1.startsWith("0")?value1.substring(1):value1}}</span><span>月</span></van-col>
        <van-col span="9" id="top2Col2"></van-col>
        <van-col span="5" id="top2Col3"></van-col>
      </van-row>
      <br>
      <van-row id="top2Row2">
        <van-col span="10" id="top2Col4"><span id="top2Span3">支出</span><span id="top2Span1">￥{{total[0]==null?0:total[0]}}</span></van-col>
        <van-col span="1" id="top2Col5"></van-col>
        <van-col span="10" id="top2Col6"><span id="top2Span4">收入</span><span id="top2Span2">￥{{ total[1]==null?0:total[1] }}</span></van-col>
      </van-row>
    </div>
    <br/>

    <div v-for="s in zhaoHangVoResList " :key=s.id >
      <span id="firstSpan">{{getDte(s.date)}}</span>
      <p></p>
      <div v-for="zhaoHangVo in s.zhaoHangVos " :key=zhaoHangVo.id >
        <van-row id="firstRow">
          <van-col span="1" id="tubiao"><van-icon name="balance-o" /></van-col>
          <van-col span="14"  id="firstCol2">{{zhaoHangVo.paidOrIncomeName}}</van-col>
          <van-col span="8" id="firstCol3">{{ zhaoHangVo.paidOrIncomeFlag }}￥{{zhaoHangVo.paidOrIncomeAmount}}</van-col>
        </van-row>
        <van-row id="secondRow">
          <van-col span="1"></van-col>
          <van-col span="14" id="secondCol2">储蓄卡8049 {{zhaoHangVo.time}}</van-col>
          <van-col span="8" id="secondCol3" >余额:￥{{ zhaoHangVo.currentAmount }}</van-col>
        </van-row>
        <br/>
      </div>

    </div>
  </div>

</template>

<script>
import { ref, onMounted } from 'vue'
import { Toast } from 'vant';
import {init} from "@/axios/api"
import {month} from "@/axios/api"
export default {
  name: "AccountView",
  // props: {
  //   "month": {
  //     required: true
  //   }
  // },

  data(){
    return{
      data:[[{time:"今天",studentCode:"10.00",studentName:"支出"},{studentCode:"20.00",studentName:"支出"}],[{time:"5.23",studentCode:"30.00",studentName:"收入"},{studentCode:"40.00",studentName:"支出"}],[{time:"5.22",studentCode:"30.00",studentName:"收入"},{studentCode:"40.00",studentName:"支出"}],[{time:"5.21",studentCode:"30.00",studentName:"收入"},{studentCode:"40.00",studentName:"支出"}],[{time:"5.20",studentCode:"30.00",studentName:"收入"},{studentCode:"40.00",studentName:"支出"}],[{time:"5.19",studentCode:"30.00",studentName:"收入"},{studentCode:"40.00",studentName:"支出"}]],
      student:{},
      pere:[],
      zhaoHangVo: {
        recordDate: '',
        recordKind: '',
        incomeAmount: '',
        paidAmount: '',
        currentAmount: '',
        paidPerson: '',
        paidSubject: '',
        paidPersonName: '',
        paidOrIncomeName: '',
        paidOrIncomeAmount: '',
        time:"",
        paidOrIncomeFlag:"",
        text:"",
        value:"",
        totalPaid:"",
        totalIn:""

      },
      zhaoHangVoResList:[],
      queryVo:{
        zhaoHangVo:{},
        pageNo:1,
        pageSize:10
      },
      dayData:{
        date:"",
        zhaoHangVos:[]

      },
      total:[]
    }
  },
  mounted() {
    if (this.$route.path==="/account"){
      console.log("this.value1:"+this.value1);
      this.queryVo.zhaoHangVo.recordDate=this.value1
      this.getInit(this.queryVo);
      console.log(JSON.stringify(this.zhaoHangVo))

    }
  },
  // mounted() {
  //   this.queryVo.zhaoHangVo.recordDate=this.value1
  //   this.getTotal(this.queryVo);
  // },
  methods: {
    async getInit(queryVo) {
      this.zhaoHangVoResList = await init(queryVo);
      this.total=[]
      this.total.push(this.zhaoHangVoResList[0].zhaoHangVos[0].totalPaid)
      this.total.push(this.zhaoHangVoResList[0].zhaoHangVos[0].totalIn)
      console.log(JSON.stringify(this.zhaoHangVoResList))
    },
    getDte(date1){
      let now=new Date();
      let day1=now.getDate()+"";
      if (day1.length==1){
        day1="0"+day1
      }
      let date=(now.getMonth()+1)+'.'+day1;
      let tom="";
      let day2=now.getDate()-1+"";
      if (day2.length==1){
        day2="0"+day2
      }
      tom=(now.getMonth()+1)+'.'+day2;
      console.log("tom:"+tom);
      console.log("date1:"+date+"date2:"+date1);
      if (date1===date){
        return "今天"
      }else if(date1===tom){
        return "昨天"
      }else{
        return date1
      }
    },

  },
  computed:{
  },
  watch: {
    value1(val, oldVal) {
      console.log("newQuestion:"+val+oldVal)
      this.queryVo.zhaoHangVo.recordDate=val
      this.getInit(this.queryVo)
    }
  },

  setup() {
    const months = ref([])
    const getMonth1= async () => {
      months.value = await month();
      value1.value=months.value[0].value
      console.log("month:"+ JSON.stringify(months.value) );
    };
    const option1 = []
    onMounted(getMonth1);
    const value1 = ref("06");
    const value2 = ref('a');
    const value3 = ref('a');


    // const option1 = [
    //   { text: '2022.03', value: "03" },
    //   { text: '2022.04', value: "04" },
    //   { text: '2022.05', value: "05" },
    // ];
    const option2 = [
      { text: '全部账户', value: 'a' },
    ];
    const option3 = [
      { text: '筛选', value: 'a' },
    ];
    const onClickLeft = () => history.back();
    const onClickRight = () => Toast('按钮');
    return {
      value1,
      value2,
      value3,
      option1,
      option2,
      option3,
      onClickLeft,
      onClickRight,
      months,
      getMonth1,

    };
  }
}
</script>

<style scoped>

#firstDiv{
  margin: 10px 0 25px 25px
}

#firstSpan{
  font-size:13px;
}
#firstCol2{
  font-size:17px;
}
#firstCol3{
  text-align:right;
  font-size:18px;
  /*font-weight:bold;*/
  /*font-family:'YouYuan'*/
}
#secondCol2{
  font-size:15px;
  color:#B0B0B0
}
#secondCol3{
  font-size:15px;
  color:#B0B0B0;
  text-align:right
}
select{
  border:0;
  box-shadow:none;
}

#top2Div {
  /* border:1px solid #F00*/
  /*background: url("../assets/001.jpg") no-repeat 4px 5px;*/
  width:340px; height:140px;
  /*background:#000;*/
  margin: 1px 1px 1px 1px;
  background: whitesmoke;

}
#top2Col1{
  text-align:center;
}
#top2Span{
  font-size: 50px;
}
#top2Col4 {
  text-align:right
}
 #top2Col6 {
  text-align:right
}
#top2Span1{
  font-size:18px;
}
#top2Span2{
  font-size:18px;
}
#top2Span3{
  font-size:15px;
}
#top2Span4{
  font-size:15px;
}
#tubiao{
  size: 100em;
}
</style>

