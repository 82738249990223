import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Cell, CellGroup,Icon ,Col, Row ,Form, Field,Button,DropdownMenu, DropdownItem,Search ,NavBar    } from 'vant'
import 'vant/lib/index.css'; // 全局引入样式
import "@/router/permission"

const app=createApp(App)

app.use(Cell);
app.use(CellGroup);
app.use(Icon);
app.use(Col);
app.use(Row);
app.use(Form);
app.use(Field);
app.use(Button);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Search);
app.use(NavBar);
app.use(store).use(router).mount('#app')
