import router from "@/router/index";
import store from "@/store/index";


router.beforeEach((to,from,next)=>{
    if (!store.state.UserToken){
        if (to.matched.length>0 && !to.matched.some(record=>record.meta.requiresAuth)){
            next();
        }else{
            next({
                path:"/login"
            })
        }
    }else {
        next();
    }
})
