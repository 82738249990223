<template>
  <van-cell-group inset>
    <van-field v-model="username" label="文本" />
    <van-field v-model="password" type="password" label="密码" />
    <van-button type="default" v-on:click="login" >登录</van-button>
  </van-cell-group>


</template>

<script>
import {login} from "@/axios/api"
export default {
  name: "LoginView",
  data(){
    return{
      username:"",
      password:""
    }
  },
  methods:{
    async login(){
      console.log("发送请求")
        let data=await login(this.username,this.password);
        let token=data.data.token;
      console.log("token"+token)
        if (token==='true'){
          this.$store.commit("LOGIN_IN",token)
          this.$router.replace("/")
        }
        console.log(data);
    }
  }
}
</script>

<style scoped>

</style>
