<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
<!--  <topView></topView>-->
  <router-view/>
</template>

<script>
// import topView from "@/views/topView"

export default {
  name: 'App',
  components: {
    // topView,
  }
}
</script>



<!--<script>-->
<!--import {topView} from "./views/topView"-->
<!--export default {-->
<!--  name: "AccountView",-->
<!--  components:{topView},-->
<!--  data(){-->
<!--  }-->
<!--}-->

<!--</script>-->


<!--<style lang="scss">-->
<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--}-->

<!--nav {-->
<!--  padding: 30px;-->

<!--  a {-->
<!--    font-weight: bold;-->
<!--    color: #2c3e50;-->

<!--    &.router-link-exact-active {-->
<!--      color: #42b983;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
