import axios from 'axios';
import store from "@/store/index";

const http={};
const config={
    baseURL: process.env.VUE_APP_URL,
    responseType: "json",
    timeout: 60000,
}
const instance=axios.create(config);
instance.defaults.timeout=5000;
instance.interceptors.request.use(
    function (config){
        // if (store.state.userToken){
        //     config.headers.Authorization=store.state.UserToken
        // }
        return config
    },
    function (error){
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response=>{
        return response.data
    },
    err=>{
        if (err&&err.response){
            switch (err.response.status){
                case 400:
                    err.message='请求出错'
                    break
                case 401:
                    store.commit('LOGIN_OUT')
                    setTimeout(()=>{
                        window.location.reload()
                    },1000)
                    return
                case 403:
                    err.message='拒绝访问'
                    break
                case 404:
                    err.message='请求错误，未找到该资源'
                    break
                case 500:
                    err.message='服务器端出错'
                    break
            }
        }else {
            err.message='连接服务器失败'
        }
        return Promise.reject(err.res);
    }
)

http.get=function (url,options){
    return new Promise((resolve)=>{
        instance.get(url,options).then(response=>{
            console.log("response1"+JSON.stringify(response))
            // if (response.code===0){
            //     console.log("code")
                resolve(response)
            // }else {
            //     reject(response.message)
            // }
        })
            .catch(e=>{
                console.log(e)
            })
    })
}

http.post=function (url,data,options){
    return new Promise((resolve) =>{
        instance.post(url,data,options)
            .then(response=>{
                // if (response.code===0){
                console.log("response:"+response)

                    resolve(response)

                // }else {
                //     reject(response.message)
                // }
            })
            .catch(e=>{
                console.log(e)
            })
    } )
}

export default http
